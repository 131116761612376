const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const Desktop = require('vis-faceted-search/lib/Desktop');
const SavedSearchesDesktop = require('vis-faceted-search/lib/SavedSearchesDesktop');
const classnames = require('classnames');
const Section = require('../../../commons/section');
const SearchByCode = require('./search-by-code/desktop');
const { getSearchUrlParams } = require('./utils/get-search-url-params');
const { buildMapSearchUrl, buildSearchUrl } = require('./utils/build-url');
const { findById } = require('./utils/find-by-attribute');
const { saveCurrentSearch, disableSavedSearchesTutorial } = require('./utils/last-search');
const restClient = require('../../../../../client/services/rest-client');


const {
  DEFAULT_URL,
  DEFAULT_MAP_URL,
  COMPONENT_IDS: { SEARCH_BY_CODE, FACETED_SEARCH, BACK },
} = require('./utils/constants');
const { trackSearchEvent, trackSearchMapEvent } = require('./utils/events');

const namespace = 'search-bar-desktop';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trigger_search_by_code: '',
      trigger_search_bar: '',
    };
    this.renderSearchByCode = this.renderSearchByCode.bind(this);
    this.renderSearchByCodeButton = this.renderSearchByCodeButton.bind(this);
    this.renderAlternativeSearchButton = this.renderAlternativeSearchButton.bind(this);
    this.changeSearchType = this.changeSearchType.bind(this);
    this.createHandleSearch = this.createHandleSearch.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
    this.handleMapSearch = this.createHandleSearch(buildMapSearchUrl, DEFAULT_MAP_URL, state => trackSearchMapEvent(state));
    this.handleSearch = this.createHandleSearch(buildSearchUrl, DEFAULT_URL, state => trackSearchEvent(state));
  }

  getInitialState() {
    const { currentSearch = {} } = this.props;
    return currentSearch;
  }

  setTriggerClass(rel, status) {
    this.setState({
      [`trigger_${rel}`]: status,
    });
  }

  changeSearchType(e) {
    e.preventDefault();

    const rel = e.target.id;

    if (rel === SEARCH_BY_CODE) {
      this.setTriggerClass('search_by_code', 'open');
      this.setTriggerClass('search_bar', 'close');
    }

    if (rel === BACK) {
      this.setTriggerClass('search_by_code', 'close');
      this.setTriggerClass('search_bar', 'open');
    }
  }

  createHandleSearch(buildUrl, defaultUrl, trackEvent) {
    const { components } = this.props;
    const { items } = findById(components, FACETED_SEARCH);

    const handleSearch = (_event, searchState) => {
      const params = getSearchUrlParams(searchState, items);

      restClient
        .get('/search/url', { params })
        .then((response) => {
          trackEvent(searchState);

          if (response?.data?.url) {
            saveCurrentSearch(searchState);
            window.location = buildUrl(response.data.url);
          } else {
            window.location = defaultUrl;
          }
        })
        .catch(() => {
          window.location = defaultUrl;
        });
    };

    return handleSearch;
  }

  renderGoBackButton({ id, label }) {
    return (
      <span
        className={classnames(
        `${namespace}__alternative-search`,
        `${namespace}__alternative-search-button`
        )}
        id={id}
        role="button"
        onClick={this.changeSearchType}
        tabIndex={0}
      >
        {label}
      </span>
    );
  }

  renderAlternativeSearchButton({ id, i18n }) {
    return (
      <span className={`${namespace}__alternative-search`}>
        {i18n.gettext('Buscar por')}
        {'\xa0'}
        <span
          className={classnames(
            `${namespace}__alternative-search-button`,
            `${namespace}__alternative-search-button--highlighted`
          )}
          role="button"
          onClick={this.changeSearchType}
          tabIndex={0}
          id={id}
        >
          {i18n.gettext('código de propiedad')}
        </span>
      </span>
    );
  }

  renderSearchByCodeButton() {
    const { i18n } = this.props;
    return this.renderAlternativeSearchButton({ id: 'search-by-code', i18n });
  }

  renderSearchByCode(){
    const { i18n } = this.props;
    const { trigger_search_by_code } = this.state;
    return (
      <Section className={classnames('search-by-code', trigger_search_by_code)}>
        <SearchByCode {...this.props}>
          {this.renderGoBackButton({ id: BACK, label: i18n.gettext('Volver al buscador principal') })}
        </SearchByCode>
      </Section>
    );
  }

  render() {
    const { defaultTitle, components } = this.props;
    const { trigger_search_bar, trigger_search_by_code } = this.state;
    const facetedSearch = findById(components, FACETED_SEARCH);
    const { savedSearches, items, hideSavedSearchesTutorial } = facetedSearch;

    if (savedSearches && !hideSavedSearchesTutorial) {
      disableSavedSearchesTutorial();
    }

    return (
      <Section className={namespace}>
        {(trigger_search_bar === '' || trigger_search_bar === 'open') && facetedSearch && (
          <div className={classnames('search-bar', trigger_search_bar)}>
            <div className={`${namespace}__background`}>
              <Desktop
                initialState={this.getInitialState()}
                renderConfig={items}
                callToAction="Buscar"
                onAction={this.handleSearch}
                secondaryAction={{ label: 'Buscar en Mapa', onAction: this.handleMapSearch }}
              >
                {this.renderSearchByCodeButton()}
                {savedSearches && (
                  <div className={`${namespace}__saved-searches`}>
                    <SavedSearchesDesktop renderConfig={savedSearches} showTooltip={!hideSavedSearchesTutorial} />
                  </div>
                )}
              </Desktop>
            </div>
          </div>
        )}
        {trigger_search_by_code === 'open' && this.renderSearchByCode()}
        <div className="main-title">
          <h1 className="main-title__text">{defaultTitle}</h1>
        </div>
      </Section>
    );
  }
}

SearchBar.propTypes = {
  siteId: PropTypes.string.isRequired,
  baseDomain: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  currentSearch: PropTypes.object.isRequired,
  components: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
};

module.exports = injectI18n(SearchBar);
