const restclient = require('nordic/restclient');
// eslint-disable-next-line import/no-extraneous-dependencies
const querystring = require('query-string');

const defaultOptions = {
  baseURL: '/pi/home/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const viewRestClient = (options) => {
  const client = restclient({ ...defaultOptions, ...options });
  client.originalDoRequest = client.doRequest;
  delete client.doRequest;
  client.doRequest = function doRequest(args) {
    return this.originalDoRequest(args)
      .catch((err) => {
        const res = err && err.response && err.response.data;
        if (res && res.code === 401 && res.data && res.data.login) {
          let stringLoginParams = '';
          if (args.loginParams) {
            stringLoginParams = `&${querystring.stringify(args.loginParams)}`;
          }
          window.location = `${res.data.login}${stringLoginParams}`;
          return;
        }
        throw err;
      });
  };
  return client;
};

module.exports = viewRestClient();
