/* eslint-env browser */

const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const React = require('react');
const hydrate = require('nordic/hydrate');

module.exports = function startApp(Component) {
  const preloadedState = window.__PRELOADED_STATE__;

  const i18n = new I18n({ translations: preloadedState.translations });

  hydrate(
    <I18nProvider i18n={i18n}>
      <Component {...preloadedState} />
    </I18nProvider>,
    document.getElementById('root-app')
  );
};
